import * as React from 'react';

import { navigate } from 'gatsby';

const ThelaymaninvestorPage = () => {
  React.useEffect(() => {
    navigate('/');
  }, []);

  return <></>;
};

export default ThelaymaninvestorPage;
